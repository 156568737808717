import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Login from "@/modules/auth/components/login";
import Register from "@/modules/auth/components/register";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        component: () => import("../modules/home/index"),
        children: [
          {
            path: "",
            name: "landingPage",
            component: () => import("../modules/home/components/landing-page"),
          },
        ],
      },

      {
        path: "questions",
        name: "questions",
        component: () => import("../modules/questions/components/question"),
      },
      {
        path: "schools",
        name: "schools",
        component: () => import("../modules/schools/index"),
      },
      {
        path: "school",
        name: "school",
        component: () => import("../modules/school/index"),
      },
      {
        path: "hall",
        name: "hall",
        component: () => import("../modules/hall/index"),
      },

      {
        path: "/login",
        name: "auth",
        component: Login,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
      {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
});

export default router;

router.beforeEach((to, from, next) => {
  // if (!to.meta.authPage && !store.getters.token) next({ name: "auth" });
  if (to.meta.authPage) document.body.classList.add("authPagesClass");
  else document.body.classList.remove("authPagesClass");
  if (to.meta.authPage && store.getters.token) next({ name: "landingPage" });
  else next();
});
