import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "./assets/styles/main.scss";

import Vuelidate from "vuelidate";
import VueYoutube from "vue-youtube";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueYoutube);
Vue.use(Vuelidate);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
